import React from 'react';
import PhotoSection from './PhotoSection';
import { AiOutlineCamera } from 'react-icons/ai';

const ShortForm = (Props) => {
  const {
    station,
    currentCheckIndex,
    setCurrentCheckIndex,
    setCamStatus,
    setPhotoMode,
    photoMode,
    handleChange,
    handleDetectAndMeasureObject,
    setStation,
    setErrorMessages

  } = Props;

  const handleCheckboxChange = () => {
    // Correctly update the nested property value2
    setStation({
      ...station,
      check: station.check.map((check, index) =>
        index === currentCheckIndex
          ? { ...check, value2: !check.value2 }
          : check
      ),
    });
  };

  const handleNextCheck = () => {
    setCurrentCheckIndex((prevIndex) =>
      prevIndex < 6 ? prevIndex + 1 : prevIndex
    );
  };

  const handlePrevCheck = () => {
    setCurrentCheckIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  };

  return (
    <div style={{ border: '1px solid black', padding: '1%' }}>
      <div>
        <p>
          <span style={{ fontWeight: 'bold' }}>שם הבדיקה:</span>{' '}
          {station.check[currentCheckIndex].CheckName}
        </p>
      </div>

      <div>
        <p style={{ fontWeight: 'bold' }}> תאור הבדיקה:</p>
        <p>{station.check[currentCheckIndex].CheckDescription}</p>
      </div>

      <br />

      <label htmlFor="finding" style={{ fontWeight: 'bold' }}>
        תאור הממצא:
      </label>
      <div
        style={{
          border: ' 1px solid #ddd',
          borderRadius: '5px',
          display: 'flex',
          marginBottom: '10px',
        }}
      >
        <input
          id="finding"
          type="text"
          name="check.value1"
          value={station.check[currentCheckIndex].value1}
          onChange={handleChange}
          style={{ border: 'none', height: '90%', width: '100%' }}
        />

        <button
          type="button"
          onClick={handleDetectAndMeasureObject}
          style={{ padding: '1%' }}
        >
          <AiOutlineCamera style={{ fontSize: 'x-large' }} />
          <span style={{ display: 'none' }}>זהה</span>
        </button>
      </div>
      <br />

      <div className="result-container">
        <label style={{width:"100%"}} htmlFor="radio-true-result">תקין</label>
        <input
          id="radio-true-result"
          className="result-input"
          type="radio"
          name={`check.value2-${currentCheckIndex}`}
          checked={(!station.number)?false:station.check[currentCheckIndex].value2}
          value={station.check[currentCheckIndex].value2 || false}
          onChange={(e)=>{
            e.preventDefault()
            if (!station.number){
              setErrorMessages("לא ניתן לבצע שינויים ללא מספר תחנה")
            }
            handleCheckboxChange(e)
        }}
        />
        <label style={{width:"100%"}} htmlFor="radio-false-result">לא תקין</label>
        <input
          id="radio-false-result"
          className="result-input"
          type="radio"
          name={`check.value2-${currentCheckIndex}`}
          checked={(!station.number)?false:!station.check[currentCheckIndex].value2}
          value={!station.check[currentCheckIndex].value2 || false}
          onChange={(e)=>{
            e.preventDefault()
            if (!station.number){
              setErrorMessages("לא ניתן לבצע שינויים ללא מספר תחנה")
            }
            handleCheckboxChange(e)
        }}
        />
      </div>
      <br />
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button type="button" onClick={handlePrevCheck}>
            {`<< בדיקה הקודמת`}
          </button>
          <button type="button" onClick={handleNextCheck}>
           {`בדיקה הבאה >>`}
          </button>
        </div>

        <PhotoSection
          station={station}
          setCurrentCheckIndex={setCurrentCheckIndex}
          currentCheckIndex={currentCheckIndex}
          setCamStatus={setCamStatus}
          setPhotoMode={setPhotoMode}
          photoMode={photoMode}
        />
      </div>
    </div>
  );
};

export default ShortForm;
