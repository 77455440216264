export const place = {
    number: '',
    name: '',
    street: '',
    satiationPhoto: [],
    check: [
      {
        CheckName: 'דרך נגישה',
        CheckDescription:
          'הדרך בקטע שבין המדרכה הסמוכה לסככת ההמתנה לתוך הסככה, וממנה לבין רחבת ההיערכות, תעמוד בדרישות ת"י 1918 חלק 1 סעיף 2.7. (במידה וקיים צומת סמוך יש לבחון הנמכת מדרכות. עד 4 הנמכות (שתי חציות)).',
        value1: '',
        value2: false,
        photo: [],
      },
      {
        CheckName: 'מכשולים בדרך',
        CheckDescription:
          'תחנה וסככת המתנה יתאימו לדרישות ת"י 1918 חלק 1 סעיף 2.9.',
        value1: '',
        value2: false,
        photo: [],
      },
      {
        CheckName: 'שטח מקום המתנה בסככה',
        CheckDescription:
          'שטח מקום ההמתנה בסככה לאדם הנע בכיסא גלגלים, בסככת המתנה, לא יפחת מ-120 ס"מ על 80 ס"מ.',
        value1: '',
        value2: false,
        photo: [],
      },
      {
        CheckName: 'רוחב מעבר פנוי',
        CheckDescription:
          'רוחב המעבר הפנוי בחזית הסככה ועד לשפת המדרכה, לא יפחת מ-110 ס"מ.',
        value1: '',
        value2: false,
        photo: [],
      },
      {
        CheckName: 'מידות רחבת היערכות',
        CheckDescription:
          'מידות רחבת ההיערכות לא יפחתו מ-250 ס"מ לאורך המדרכה ומ-200 ס"מ לרוחבה.',
        value1: '',
        value2: false,
        photo: [],
      },
      {
        CheckName: 'סימון רחבת היערכות',
        CheckDescription:
          'מסגרת הרחבה תסומן בפס בצבע כחול ברוחב של 8 ס"מ לפחות צמוד לדופן סככת ההמתנה שבצד הפונה לכיוון שממנו מגיע האוטובוס.',
        value1: '',
        value2: false,
        photo: [],
      },
      {
        CheckName: 'תחנת עמוד',
        CheckDescription:
          'הוצב בתחנה עמוד בלבד, תסומן המסגרת של רחבת ההיערכות במרחק של 5.5 מטרים מהכיוון שממנו מגיע האוטובוס.',
        value1: '',
        value2: false,
        photo: [],
      },
    ],
  };