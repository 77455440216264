
import Tesseract from 'tesseract.js';
import onnxruntime  from "onnxruntime-web";
const yolo_classes = ["blue-box", "bus-station-sing", "number", "streat", "streat and number"];


async function detectObjectsOnImage(imageSrc, session,modelInputShape) {
    const [input, img_width, img_height] = await prepareInput(imageSrc);
    
    const output = await runModel(input, session,modelInputShape);
   
    return processOutput(output, img_width, img_height);
}


async function prepareInput(buf) {  
    const img = await Jimp.read(buf);
    const img_width = img.bitmap.width;
    const img_height = img.bitmap.height;
    const resizedImg = img.clone().resize(640, 640, Jimp.RESIZE_FILL);
    const pixels = resizedImg.bitmap.data;
    const red = [], green = [], blue = [];
    for (let index = 0; index < pixels.length; index += 4) {
        red.push(pixels[index] / 255.0);
        green.push(pixels[index + 1] / 255.0);
        blue.push(pixels[index + 2] / 255.0);
    }

    const input = [...red, ...green, ...blue];
    return [input, img_width, img_height];
}


async function runModel(oInput, session,modelInputShape) {  
  
    try {       
        const input = new onnxruntime.Tensor("float32", Float32Array.from(oInput),modelInputShape);
        const outputs = await session.run({ images: input });         
        return outputs.output0.cpuData;
    } catch (error) {
        console.error('Error loading ONNX model:', error);
        throw error;
    }
}

function processOutput(output, img_width, img_height) {
    console.log("process_output");
    let boxes = [];
    for (let index=0;index<8400;index++) {
        const [class_id,prob] = [...Array(80).keys()]
            .map(col => [col, output[8400*(col+4)+index]])
            .reduce((accum, item) => item[1]>accum[1] ? item : accum,[0,0]);
        if (prob < 0.5) {
            continue;
        }
        const label = yolo_classes[class_id];
        const xc = output[index];
        const yc = output[8400+index];
        const w = output[2*8400+index];
        const h = output[3*8400+index];
        const x1 = (xc-w/2)/640*img_width;
        const y1 = (yc-h/2)/640*img_height;
        const x2 = (xc+w/2)/640*img_width;
        const y2 = (yc+h/2)/640*img_height;
        boxes.push([x1,y1,x2,y2,label,prob]);
    }
    boxes = boxes.sort((box1,box2) => box2[5]-box1[5])
    const result = [];
    while (boxes.length>0) {
        result.push(boxes[0]);
        boxes = boxes.filter(box => iou(boxes[0],box)<0.7);
    }
    // console.log("process_output : ",result);
    return result;
}

function iou(box1,box2) {
   
    return intersection(box1,box2)/union(box1,box2);
}
function union(box1,box2) {
    const [box1_x1,box1_y1,box1_x2,box1_y2] = box1;
    const [box2_x1,box2_y1,box2_x2,box2_y2] = box2;
    const box1_area = (box1_x2-box1_x1)*(box1_y2-box1_y1)
    const box2_area = (box2_x2-box2_x1)*(box2_y2-box2_y1)
    return box1_area + box2_area - intersection(box1,box2)
}
function intersection(box1,box2) {
    const [box1_x1,box1_y1,box1_x2,box1_y2] = box1;
    const [box2_x1,box2_y1,box2_x2,box2_y2] = box2;
    const x1 = Math.max(box1_x1,box2_x1);
    const y1 = Math.max(box1_y1,box2_y1);
    const x2 = Math.min(box1_x2,box2_x2);
    const y2 = Math.min(box1_y2,box2_y2);
    return (x2-x1)*(y2-y1)
}

async function measureObject(image, session,modelInputShape) {
    const detections = await detectObjectsOnImage(image, session,modelInputShape);
    const matchingBox = findMatchingBox(detections, "blue-box");
    if (!matchingBox) return { width: null, height: null };
    const [x1, y1, x2, y2] = matchingBox;
    const width = Math.round((x2 - x1) * 2.54 / 96 * 5.1);
    const height =  Math.round((y2 - y1) * 2.54 / 96 * 13); 
    return { width, height };
}

function findMatchingBox(detections, nameToFind) {
    for (const detection of detections) {
        if (nameToFind === detection[4]) {
            return [detection[0], detection[1], detection[2], detection[3]];
        }
    }
    return null;
}

async function cropImage(img, box) {
    const [x1, y1, x2, y2] = box;
    const left = Math.round(x1);
    const top = Math.round(y1);
    const width = Math.round(x2 - x1);
    const height = Math.round(y2 - y1);
    const image = await Jimp.read(img);
    const croppedImageBuffer = await image.crop(left, top, width, height).getBufferAsync(Jimp.MIME_PNG);
    return croppedImageBuffer;
}

async function performOCR(croppedImageBuffer) {
    try {
        const { data: { text } } = await Tesseract.recognize(croppedImageBuffer, 'eng');
        return text.trim();
    } catch (error) {
        console.error('Error performing OCR:', error.message);
        throw error;
    }
}

async function detectNumbers(buffer, session,modelInputShape) {
    // resize image to 640x640      
    const resizeBuf = await Jimp.read(buffer).then(image => image.resize(640, 640).getBufferAsync(Jimp.MIME_PNG));
    const buf = Buffer.from(resizeBuf);  
    const [numberResults, busStationResults, streatAndNumberResults] = await Promise.all([
        detectAndCrop('number', buf, session,modelInputShape),
        detectAndCrop('bus-station-sing', buf, session,modelInputShape),
        detectAndCrop('streat and number', buf, session,modelInputShape)
    ]);

    if (numberResults) {
        return await performOCR(numberResults);
    } else if (busStationResults) {
        const number = await detectAndCrop('number', busStationResults, session,modelInputShape);
        return await performOCR(number);
    } else if (streatAndNumberResults) {
        const number = await detectAndCrop('number', streatAndNumberResults, session,modelInputShape);
        return await performOCR(number);
    } else {
        console.log('Detection and OCR failed');
    }
}

async function detectAndCrop(nameToFind, buf, session,modelInputShape) {
    try {
        const detections = await detectObjectsOnImage(buf, session,modelInputShape);
        if (!detections) return null;
        const matchingBox = findMatchingBox(detections, nameToFind);
        if (matchingBox) {
            return await cropImage(buf, matchingBox);
        } else {
            return null;
        }
    } catch (error) {
        console.log('Error in detectAndCrop:', error);
        throw error;
    }
}

export { detectObjectsOnImage, measureObject, findMatchingBox, cropImage, performOCR, detectNumbers };
