import React from 'react';
import PhotoSection from './PhotoSection';
import { AiOutlineCamera } from 'react-icons/ai';

const LongForm = (Props) => {
  const {
    index,
    station,
    setStation,
    currentCheckIndex,
    check,
    setCamStatus,
    setPhotoMode,
    photoMode,
    handleDetectAndMeasureObject,
    setCurrentCheckIndex,
    setErrorMessages,
    finding
  } = Props;
  const handleChange = (e) => {
    // Update the nested properties correctly
    if (e.target.name.startsWith('check.') && station.check) {
      const checkField = e.target.name.split('.')[1];
      setStation({
        ...station,
        check: station.check.map((check, index) =>
          index === currentCheckIndex
            ? { ...check, [checkField]: e.target.value }
            : check
        ),
      });
    } else {
      setStation({ ...station, [e.target.name]: e.target.value });
    }
  };

  const handleCheckboxChange = () => {
    // Correctly update the nested property value2
    setStation({
      ...station,
      check: station.check.map((check, index) =>
        index === currentCheckIndex
          ? { ...check, value2: !check.value2 }
          : check
      ),
    });
  };
  return (
    <div
      key={currentCheckIndex}
      style={{ border: '1px solid black', padding: '1%', marginBottom: '2%' }}
    >
      <div>
        <p>
          <span style={{ fontWeight: 'bold' }}>שם הבדיקה:</span>{' '}
          {station.check[currentCheckIndex].CheckName}
        </p>
      </div>

      <div>
        <p style={{ fontWeight: 'bold' }}> תאור הבדיקה:</p>
        <p>{station.check[currentCheckIndex].CheckDescription}</p>
      </div>

      <br />

      <label htmlFor={"finding" + index.toString()} style={{ fontWeight: 'bold' }}>
        תאור הממצא:
      </label>
      <div
        style={{
          border: ' 1px solid #ddd',
          borderRadius: '5px',
          display: 'flex',
          marginBottom: '10px',
        }}
      >
        <input
          id={"finding" + index.toString()}
          type="text"
          name="check.value1"
          value={check.value1 || finding || ''}
          onChange={handleChange}
          style={{ border: 'none', height: '90%', width: '100%' }}
        />

        <button
          type="button"
          onClick={handleDetectAndMeasureObject}
          style={{ padding: '1%' }}
        >
          <AiOutlineCamera style={{ fontSize: 'x-large' }} />
          <span style={{ display: 'none' }}>זהה</span>
        </button>
      </div>
      <br />

      <div className="result-container">
        <label style={{width:"100%"}} htmlFor={"radio-true-result" + index.toString()}>תקין</label>
        <input
          id={"radio-true-result" + index.toString()}
          className="result-input"
          type="radio"
          name={`check.value2-${index}`}
          checked={(!station.number)?false:station.check[currentCheckIndex].value2}
          value={station.check[currentCheckIndex].value2 || false}
          onChange={(e)=>{
            e.preventDefault()
            if (!station.number){
              setErrorMessages("לא ניתן לבצע שינויים ללא מספר תחנה")
            }
            handleCheckboxChange(e)
        }}
        />
        <label style={{width:"100%"}} htmlFor={"radio-false-result" + index.toString()}>לא תקין</label>
        <input
          id={"radio-false-result" + index.toString()}
          className="result-input"
          type="radio"
          name={`check.value2-${index}`}
          checked={(!station.number)?false:!station.check[currentCheckIndex].value2}
          value={!station.check[currentCheckIndex].value2 || false}
          onChange={(e)=>{
            e.preventDefault()
            if (!station.number){
              setErrorMessages("לא ניתן לבצע שינויים ללא מספר תחנה")
            }
            handleCheckboxChange(e)
        }}
        />
      </div>
      <br />
      <div>
        <PhotoSection
          station={station}
          setCurrentCheckIndex={setCurrentCheckIndex}
          currentCheckIndex={currentCheckIndex}
          setCamStatus={setCamStatus}
          setPhotoMode={setPhotoMode}
          photoMode={photoMode}
        />
      </div>
    </div>
  );
};

export default LongForm;
