import React,{useState} from 'react'
import { AiOutlineCamera } from 'react-icons/ai';

const PhotoSection = (Props) => {
    const { station, currentCheckIndex, setCamStatus, setPhotoMode, photoMode, setCurrentCheckIndex, satiationPhoto = false } = Props;
    const [showOriginalPhoto, setShowOriginalPhoto] = useState(false);
    const [originalPhotoUrl, setOriginalPhotoUrl] = useState('');

    const handleMiniPhotoClick = (photoUrl) => {
        setOriginalPhotoUrl(photoUrl);
        setShowOriginalPhoto(true);
    };

    const handleCloseOriginalPhoto = () => {
        setShowOriginalPhoto(false);
        setOriginalPhotoUrl('');
    };

    return (
        <div>
            <p style={{ fontWeight: "bold" }}>תמונות:</p>
            <div id="photo-container" className="photo-container">
                <button
                    // camera button
                    type="button"
                    className="margin-right"
                    onClick={() => {
                        console.log('currentCheckIndex', currentCheckIndex)
                        setPhotoMode(!photoMode);
                        setCurrentCheckIndex(currentCheckIndex);
                        setCamStatus(satiationPhoto === false ? 'photoMode' : 'satiationPhoto');
                    }}
                >
                    <AiOutlineCamera style={{ fontSize: 'x-large' }} />
                    <span style={{ display: 'none' }}>צלם</span>
                </button>

                {satiationPhoto === false ?
                    station.check[currentCheckIndex].photo &&
                    station.check[currentCheckIndex].photo.map((photo, index) => (
                        <div id={`photo-${currentCheckIndex}-${index}`} key={index}>
                            <img
                                src={                                  
                                    photo && photo.original_photo_buffer 
                                    ? photo.original_photo_buffer 
                                    : photo && photo.path_to_original_photo // Added null check for photo
                                        ? photo.path_to_original_photo.startsWith('/home/bizbagis/')
                                            ? photo.path_to_original_photo.replace('/home/bizbagis/', 'https://')
                                            : photo.path_to_original_photo
                                        : null                                      
                                }
                                //add alt with
                                alt={`finding no : ${currentCheckIndex}`}
                                onClick={() => handleMiniPhotoClick(photo.original_photo_buffer)}
                            />
                            <button
                                type="button"
                                onClick={() => {
                                    const photoElement = document.getElementById(`photo-${currentCheckIndex}-${index}`);
                                    if (photoElement) photoElement.style.display = 'none';
                                }}
                            >
                                X
                            </button>
                        </div>
                    ))
                    :
                    (station.satiationPhoto && station.satiationPhoto.map((photo, index) => (
                        photo ? (
                            <div id={`satiationPhoto-${currentCheckIndex}-${index}`} key={index}>
                                <img
                                    src={
                                        photo && photo.original_photo_buffer 
                                        ? photo.original_photo_buffer 
                                        : photo && photo.path_to_original_photo // Added null check for photo
                                            ? photo.path_to_original_photo.startsWith('/home/bizbagis/')
                                                ? photo.path_to_original_photo.replace('/home/bizbagis/', 'https://')
                                                : photo.path_to_original_photo
                                            : null
                                    }
                                    //add alt with
                                    alt={`satiationPhoto finding no : -${currentCheckIndex}-${index}`}
                                    onClick={() => handleMiniPhotoClick(photo.original_photo_buffer)}
                                />
                                <button
                                    type="button"
                                    onClick={() => {
                                        const photoElement = document.getElementById(`satiationPhoto-${currentCheckIndex}-${index}`);
                                        if (photoElement) photoElement.style.display = 'none';
                                    }}
                                >
                                    X
                                </button>
                            </div>
                        ) : null
                    )))
                }




            </div>
            {showOriginalPhoto && (
                <div className="original-photo-overlay" onClick={handleCloseOriginalPhoto}>
                    <div className="original-photo-container">
                        <img src={originalPhotoUrl} alt="Original" />
                    </div>
                </div>
            )}
        </div>
    ) 


    


}


export default PhotoSection