
import React, { useState,useEffect } from 'react';
import Cam from './Cam';
import Form from './Form';
import axios from 'axios';
import { place as baseStation } from './scimas/placeScima';
import { InferenceSession } from "onnxruntime-web";
import './App.css';

// import loading from './assets/loading1.gif';

const images = require.context('./assets', true);//import dynamically all gifs from the assets folder
const loading = images('./loading1.gif');// import the loading1 gif from the assets folder
//  const baseURL = 'http://localhost:5000/api';
const baseURL =  'https://bus-check.biznagish.co.il/api';

// create an instance of axios with the options in the client side
const api = axios.create({  
  baseURL: baseURL, 
  timeout: 1000000,
  maxContentLength: 200000000,
  //withCredentials: true, // To allow cookies
  headers: {
    'Content-Type': 'application/json',   

  },
});
// eslint-disable-next-line
function getObjectSizeInMB(obj) {
  const jsonString = JSON.stringify(obj);
  const bytes = new Blob([jsonString]).size;
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(2);
}
function App() {
  const [currentCheckIndex, setCurrentCheckIndex] = useState(0);
  const [photoMode, setPhotoMode] = useState(false);
  const [number, setNumber] = useState(null);
  const [finding, setFinding] = useState(null);
  const [camStatus, setCamStatus] = useState('photoMode'); // or ocrMode
  const [errorMessages, setErrorMessages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [station, setStation] = useState(baseStation);
  const [longDisplay, setLongDisplay] = React.useState(false)
  const [session, setSession] = useState(null);
  
  const fetchStationByStationNumber = (StationNumber) => {
    if (!StationNumber) return
    try {
      setIsLoading(true);
      api
        .get(`/stations/number/${StationNumber}`)
        .then((response) => {
          setIsLoading(false);
          if (response.data) {
            setStation(response.data);
          } else {
            // Handle the case where the response data is empty
            console.error('Empty response data from the server');
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
          setErrorMessages('מספר התחנה לא קיים');
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    // Update the nested properties correctly
    if (e.target.name.startsWith('check.') && station.check) {
      const checkField = e.target.name.split('.')[1];
      setStation({
        ...station,
        check: station.check.map((check, index) =>
          index === currentCheckIndex
            ? { ...check, [checkField]: e.target.value }
            : check
        ),
      });
    } else {
      setStation({ ...station, [e.target.name]: e.target.value });
    }
  };

const handleSubmit =  (e) => {
 e.preventDefault();
 setIsLoading(true);
  api.post('/stations', station).then((response) => {
    setIsLoading(false);
    setErrorMessages('התחנה נשמרה בהצלחה');
  }).catch((error) => {
    console.error(error);
    setIsLoading(false);
    setErrorMessages('שגיאה בשמירת התחנה \n '+error);
  });
};

// eslint-disable-next-line
  const handleExportToExcel = async () => {
    try {
      window.location.href = '/toExcel';
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const handleCapture = (imageSrc) => {
    // Correctly update the nested property photo, if not present create it
// console.log('imageSrc',imageSrc)
    setStation({
      ...station,
      check: station.check.map((check, index) =>
        index === currentCheckIndex
          ? {
              ...check,
              // Check if photo is an array, if not initialize it as an empty array
              // path_to_original_photo:String,mini_photo_buffer:Buffer,original_photo_buffer:String
              photo: [...check.photo,{path_to_original_photo:null,mini_photo_buffer:null,original_photo_buffer: imageSrc}],
            }
          : check
      ),
    });
    setPhotoMode(false);
  };

  const handleCaptureSatiationPhoto = (imageSrc) => {
    console.log('imageSrc',imageSrc)
    // Correctly update the nested property satiationPhoto as array, if not present create it
    setStation({
      ...station,
      satiationPhoto: 
        [...station.satiationPhoto, {path_to_original_photo:null,mini_photo_buffer:null,original_photo_buffer: imageSrc}]
       
    });

    setPhotoMode(false);
  };



  const modelName = "besta.onnx";
  const modelInputShape = [1, 3, 640, 640];
  const classThreshold = 0.2;

  useEffect(() => {
    const runyolo = async () => {
  // cv.onRuntimeInitialized = async () => {
    try {
      // create session
    console.log('Loading YOLO model...');
    setIsLoading(true) 
    const yolo = await InferenceSession.create(`${process.env.PUBLIC_URL}/model/${modelName}`);  
    console.log('YOLO model loaded.');
    setSession(yolo);
    setIsLoading(false);
    } catch (error) {
      console.error(error);
      
    }
    
  }
  runyolo();
  // cv.onRuntimeInitialized();
},[]);

return (
  <div className={photoMode === false ? "container" : "containerNoMaxWidth"}>
    {errorMessages ? (
      <div className="loading-container error-messages">
        <h1 onClick={() => setErrorMessages(null)}>{errorMessages}</h1>
      </div>
    ) : null}
    {isLoading ? (
      <div className="loading-container">
        <img src={loading} alt="loading" />
      </div>
    ) : null}
    {photoMode === false && !errorMessages && (
      <h1 style={{ display: "flex", justifyContent: "center" }}>
        מתעד התחנות V.3 {baseURL.includes("localhost") ? " גרסה מקומית" : null}
      </h1>
    )}
    {photoMode === false ? (
      errorMessages === null ? (
        <Form
          station={station}
          setStation={setStation}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          finding={finding}
          currentCheckIndex={currentCheckIndex}
          setCurrentCheckIndex={setCurrentCheckIndex}
          photoMode={photoMode}
          setPhotoMode={setPhotoMode}
          number={number}
          findStation={fetchStationByStationNumber}
          setCamStatus={setCamStatus}
          setNumber={setNumber}
          longDisplay={longDisplay}
          setLongDisplay={setLongDisplay}
          setErrorMessages={setErrorMessages}
        />
      ) : null
    ) : isLoading === false && errorMessages === null ? (
      <Cam
        setIsLoading={setIsLoading}
        handleCapture={handleCapture}
        handleCaptureSatiationPhoto={handleCaptureSatiationPhoto}
        camStatus={camStatus}
        setPhotoMode={setPhotoMode}
        setCamStatus={setCamStatus}
        setNumber={setNumber}
        host={baseURL}
        session={session}
        modelInputShape={modelInputShape}
        classThreshold={classThreshold}
        setFinding={setFinding}
        findStation={fetchStationByStationNumber}
      />
    ) : null}
    {/* <button onClick={handleExportToExcel}>יצא לאקסל</button> */}
  </div>
);

}

export default App;
