import React from 'react'
// import { FcCamera  } from "react-icons/fc";
import { AiOutlineCamera } from 'react-icons/ai'
import LongForm from './LongForm'
import PhotoSection from './PhotoSection'
import ShortForm from './ShortForm'
import { place as baseStation } from './scimas/placeScima';
// import './toggel.css'

const Form = props => {
  const {
    station,
    handleChange,
    handleCheckboxChange,
    handleSubmit,
    number,    
    setNumber,
    currentCheckIndex,
    findStation, 
    setStation,
    photoMode,
    setPhotoMode,
    setCamStatus,
    setCurrentCheckIndex,
    longDisplay,
    setLongDisplay,
    setErrorMessages,
    finding
  } = props

  

  const handleDetectAndMeasureObject = () => {
    setPhotoMode(true)
    setCamStatus('detectMode')
  }

  const handleOcrMode = () => {
    setPhotoMode(true)
    setCamStatus('ocrMode')
  }

  return (
    <form onSubmit={handleSubmit}  >
      <div style={{ border: '1px solid black', padding: '1%' }}>
        <div>
          <label htmlFor='stationNumInput' style={{ fontWeight: 'bold' }}>
            מספר תחנה:
          </label>
          <div id='stationNum' style={{ display: 'flex', height: '45px' }}>
            <div
              style={{
                border: ' 1px solid #ddd',
                borderRadius: '5px',
                display: 'flex',
                marginBottom: '10px'
              }}
            >
              <input 
                id='stationNumInput'
                type='text'
                name='number'
                value={station.number || number || ''}
                onChange={(e)=>{
                  setNumber(e.target.value || number || '')
                  handleChange(e);
                } }
                style={{ border: 'none', height: '90%', width: '100%' }}
              />
              <button
                type='button'
                onClick={handleOcrMode}
                style={{ padding: '1%' }}
              >
                <AiOutlineCamera style={{ fontSize: 'x-large' }} />
                <span style={{ display: 'none' }}>זהה</span>
              </button>
            </div>

            <div
              style={{
                marginBottom: '10px',
                marginRight: '1%',
                display: 'flex'
              }}
            >
              <button
                type='button'
                style={{margin: "0 auto"}}
                onClick={(e) => {
                  e.preventDefault()
                  setStation(baseStation) // set new station
                  setNumber(null)  //set stationNumInput to empty
                }}
              >
                חדש
              </button>
              <button
                type='button'
                style={{margin: "0 auto"}}
                disabled={number ? false : true}
                onClick={e => {
                  //get target by id
                  e.preventDefault()
                  // const stationNum = document.getElementById('stationNumInput').value
                  findStation(number)
                }}
              >
                חפש
              </button>
              <button type='submit'  style={{margin: "0 auto"}}  disabled={number ? false : true}>הוסף/עדכן</button>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <label style={{ fontWeight: 'bold', width: '100%' }}>
            שם תחנה:
            <input
              type='text'
              name='name'
              value={station.name}
              onChange={handleChange}
            />
          </label>
          <label
            style={{ fontWeight: 'bold', marginRight: '1%', width: '40%' }}
          >
            רחוב:
            <input
              type='text'
              name='street'
              value={station.street}
              onChange={handleChange}
            />
          </label>
        </div>
        <PhotoSection
          station={station}
          currentCheckIndex={currentCheckIndex}
          setCamStatus={setCamStatus}
          setPhotoMode={setPhotoMode}
          photoMode={photoMode}
          satiationPhoto={true}
          setCurrentCheckIndex={setCurrentCheckIndex}
        />
      </div>

      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
      <div style={{ width: "100%" , height:"0", marginTop:"1%", position:"relative"}}>
         {longDisplay === true ? (
          <button
            style={{ position: "absolute", top: "0", left:"0" }}
            title="הצג פחות"
            onClick={(e) => {
              e.preventDefault()
              setLongDisplay(!longDisplay)}}
          >
            ^
          </button>
        ) : (
          <button
            style={{ position: "absolute", top: "0", left:"0", transform: "rotate(180deg)" }}
            title="הצג עוד"
            onClick={(e) => {
              e.preventDefault()
              setLongDisplay(!longDisplay)}}
          >
            ^
          </button>
        )}
      
        
      </div>     
    </div>
        {/* תצוגה מפורטת */}
      {longDisplay === true ? station.check.map(
            (check, index) => (             
              
                <LongForm
                  key={index}
                  index={index}
                  currentCheckIndex={index}
                  setCurrentCheckIndex={setCurrentCheckIndex}
                  check={check}
                  handleDetectAndMeasureObject={handleDetectAndMeasureObject}
                  photoMode={photoMode}
                  setPhotoMode={setPhotoMode}
                  setCamStatus={setCamStatus}
                  station={station}
                  setStation={setStation}
                  setErrorMessages={setErrorMessages}
                  finding={finding}
                />              
            )
          )
        : null}
       {/* תצוגה מפורטת */}
      {longDisplay === false ? (
        <ShortForm
          station={station}
          setStation={setStation}
          currentCheckIndex={currentCheckIndex}
          setCurrentCheckIndex={setCurrentCheckIndex}
          setCamStatus={setCamStatus}
          setPhotoMode={setPhotoMode}
          photoMode={photoMode}
          handleChange={handleChange}
          handleDetectAndMeasureObject={handleDetectAndMeasureObject}
          handleCheckboxChange={handleCheckboxChange}
          setErrorMessages={setErrorMessages}
          finding={finding}

        />
      ) : null}
    </form>
  )
}

export default Form
