import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import { Button } from 'semantic-ui-react';
import { detectNumbers, measureObject, detectObjectsOnImage, findMatchingBox } from './modleHelper';

const Cam = (props) => {
  const { camStatus, setNumber, setFinding, setPhotoMode, handleCapture, setIsLoading, handleCaptureSatiationPhoto, host, session, findStation, modelInputShape } = props;
  const webcamRef = useRef(null);
  const [zoom, setZoom] = useState(1);

  // const [coordinates, setCoordinates] = useState(null);
  const [captureButtonPressed, setCaptureButtonPressed] = useState(false);





  const handleZoomIn = () => {
    setZoom(Math.min(10, zoom + 1)); // Increase zoom level, capped at 10
  };
  const handleZoomOut = () => {
    setZoom(Math.max(1, zoom - 1)); // Decrease zoom level, capped at 1 (minimum)
  };

  useEffect(() => {
    applyZoomConstraints(zoom);
  }, [zoom]);

  const applyZoomConstraints = async (zoomLevel) => {
    if (!webcamRef.current) return;

    const videoTrack = webcamRef.current.video.srcObject?.getVideoTracks()[0];
    // get the best camera

    if (!videoTrack) return;

    try {
      await videoTrack.applyConstraints({ advanced: [{ zoom: zoomLevel }] });
    } catch (error) {
      if (error.name === 'OverconstrainedError') {
        console.warn('Unsupported zoom level:', zoomLevel);
      } else {
        console.error('Error applying zoom constraints:', error);
      }
    }
  };

  // Function to detect numbers using local OCR
  // eslint-disable-next-line
  const detectNumbersUsingServer = async (image) => {
    setIsLoading(true);
    const blob = await fetch(image).then((res) => res.blob());
    const file = new File([blob], 'image.JPEG', { type: 'image/JPEG' });

    const formData = new FormData();
    formData.append('image', file);
    const path = '/detect-and-crop-and-ocr';
    // use fetch to send file to OCR server
    fetch(host + path, {
      method: 'POST',
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          setNumber(null);
          setPhotoMode(false);
          setIsLoading(false);
          throw new Error(`Failed to fetch. HTTP status ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setNumber(data);
        setPhotoMode(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Fetch error:', error.message);

      });


  };

  const detectAndMeasureObjectUsingServer = async (image) => {
    setIsLoading(true)
    const blob = await fetch(image).then((res) => res.blob());
    const file = new File([blob], 'image.JPEG', { type: 'image/JPEG' });

    const formData = new FormData();
    formData.append('image', file);
    const path = '/detect-and-measure-object';

    // use fetch to send file to api server
    fetch(host + path, {
      method: 'POST',
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setPhotoMode(false);
      })
      .catch((error) => {
        console.error('Fetch error:', error.message);
        // Handle the error appropriately, such as showing a user-friendly message
        // or retrying the request.
      });
  };

  const detectAndMeasureObjectLocal = async (image) => {
    setIsLoading(true);
    if (!session) {
      console.error('Session is not initialized.');
      setIsLoading(false);
      return;
    }
    const detections = await detectObjectsOnImage(image, session,modelInputShape);
    const matchingBox = findMatchingBox(detections, "blue-box");
    if (matchingBox) {
      const [x1, y1, x2, y2] = matchingBox;
      let width = x2 - x1;
      let height = y2 - y1;
      width = (width * 2.54 / 96) * 5.1;
      height = (height * 2.54 / 96) * 13;
      width = Math.round(width);
      height = Math.round(height);
      setFinding({ width, height });
    } else {
      setFinding({ width: null, height: null });
    }
    setIsLoading(false);
    setPhotoMode(false);
  }

  const detectNumberLocal = async (imageBlob) => {
    setNumber('');
    setIsLoading(true);
    try {
      console.log('detectNumberLocal', imageBlob);
      if (!session) {
        console.error('Session is not initialized.');
        setIsLoading(false);
        return;
      }
      const number = await detectNumbers(imageBlob, session, modelInputShape);
      
      //if number is not null, find the station
      if (number) {
        findStation(number);
      } 
      
      setNumber(number);
      setIsLoading(false);
      setPhotoMode(false);
    } catch (error) {
      console.error('Error:', error);
    }
    setIsLoading(false);
    setPhotoMode(false);
  };


  const capture = React.useCallback(
    async () => {
      console.log('capture');
      const imageSrc = webcamRef.current.getScreenshot();
      // convert imageSrc to blob then to buffer
      const blob = await fetch(imageSrc).then((res) => res.blob());
      const buffer = await blob.arrayBuffer();


      switch (camStatus) {
        case 'photoMode': handleCapture(imageSrc); break;
        case 'satiationPhoto': handleCaptureSatiationPhoto(imageSrc); break;
        case 'ocrMode': detectNumberLocal(buffer, session); break; //detectNumber(imageSrc); break;
        case 'detectMode': detectAndMeasureObjectLocal(buffer, session); break; // local
        // case 'ocrMode': detectNumbersUsingServer(imageSrc); break; //detectNumber(imageSrc); break;
        // case 'detectMode': detectAndMeasureObjectUsingServer(imageSrc); break;
        default: console.error('Unknown camStatus:', camStatus);
      }
      setCaptureButtonPressed(true);

    },
    // eslint-disable-next-line
    [webcamRef]
  );



  return (
    <div id="Webcam-Container" className="webcam-container">

      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/JPEG"
        videoConstraints={{
          facingMode: "environment"
        }}
      />

      <Button
        className='snap-shot-button'
        onClick={capture}
      >
        צלם
      </Button>

      <button
        className="back-button"
        onClick={() => setPhotoMode(false)}
      >
        חזור
      </button>

      <div className='zoom-buttons'>
        <Button onClick={handleZoomIn} >+</Button> {/* Add button for zoom in */}
        <Button onClick={handleZoomOut}>-</Button> {/* Add button for zoom out */}
      </div>

    </div>
  );
};

export default Cam;
